import React, {Component} from "react";
import SectionTitle from "../components/SectionTitle";

const DressCodeSection = (props) => {
    return <section className={`wpo-portfolio-section section-padding ${props.pClass}`} id="dress-code">
        <div className="container">
            <SectionTitle MainTitle={'Dress Code'}/>
            <div className={"subhead"}>
                <center>
                    <p>Think "<strong>Garden Party meets Cocktail</strong>"</p>
                    <br/>
                    <p>The ceremony is going to be a garden party, and the weather will be moderately warm.</p>
                    <p>The dinner afterward will be a private dinner party at a group table in the city.</p>
                    <p>Wear what is comfortable for the weather, and will be adaptable from the daytime party to the evening.</p>
                    <br/>
                    <p>For the ladies, a nice dress, pantsuit, really anything you like! (Just not white 😉)</p>
                    <p>For the gents, a nice dress shirt and trousers or chinos. Suits / jackets optional, the groom will be dressed up.</p>
                </center>
            </div>
        </div>
    </section>
}
export default DressCodeSection;