import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import DetailsSection from '../../components/StorySection';
import CoupleSection from '../../components/CoupleSection'
import VideoSection from '../../components/VideoSection'
import PortfolioSection2 from '../../components/PortfolioSection2';
import TimeAndPlace from '../../components/EventSection';
import RSVP from '../../components/RSVP';
import BlogSection from '../../components/BlogSection';
import Scrollbar from '../../components/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer';
import OfferSection from "../../components/OfferSection";
import PricingSection from "../../components/PricingSection";
import ProjectSection from "../../components/ProjectSection";
import FunFact from "../../components/FunFact";
import BlogSection2 from "../../components/BlogSection2";
import BannerSection from "../../components/BannerSection";
import DressCodeSection from "../../sections/DressCodeSection";
import GallerySection from "../../sections/GallerySection";
import TransportSection from "../../sections/TransportSection";



const HomePage =() => {

    return(
        <Fragment>
            <Navbar hclass={'wpo-site-header-s1'}/>
            <Hero/>

            <TimeAndPlace/>
            <DressCodeSection/>
            <TransportSection/>

            <DetailsSection/>
            <GallerySection/>

            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage;