import React, {Component} from "react";
import SectionTitle from "../components/SectionTitle";

const DressCodeSection = (props) => {
    return <section className={`wpo-portfolio-section section-padding ${props.pClass}`} id="gallery">
        <div className="container">
            <SectionTitle MainTitle={'Gallery'}/>
            <center><p>Watch this space! We will share the photo gallery after the big day</p></center>
            {/*<div className="sortable-gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix">
                                {Portfolios.map((portfolio, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.Pimg}
                                                image={portfolio.Pimg}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>*/}
        </div>
    </section>
}
export default DressCodeSection;