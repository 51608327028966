import React from 'react'
import SectionTitle from '../SectionTitle'
import LocationMap from '../Modal'

import delamore_1 from '../../images/_/delamore-roundabout.jpg'
import delamore_2 from '../../images/_/delamore-driveway.jpg'
import donnachang_1 from '../../images/_/donna-chang-mez-1.jpg'
import donnachang_2 from '../../images/_/donna-chang-entrance.jpg'
import CustomCarousel from "../CustomCarousel";


const Events = [
    {
        images: [delamore_1, delamore_2],
        imageCaptions: ['Delamore Roundabout', 'Delamore Driveway'],
        title: 'The Ceremony',
        li1: 'Garden Party',
        li2: '(Arrive 2:30!) 3:00pm - 5:00pm',
        address: 'Delamore, 115 Turner Rd, Kedron QLD 4031',
        mapsUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3542.0250252207984!2d153.02311877715286!3d-27.40614871400472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91582520a5ecc7%3A0xc98ed8e80100e183!2sDelamore%20Retirement%20Community!5e0!3m2!1sen!2sau!4v1708381740278!5m2!1sen!2sau`
    },
    {
        images: [donnachang_1, donnachang_2],
        imageCaptions: ['Donna Chang Mezzanine', 'Donna Chang Entrance'],
        title: 'The Reception',
        li1: 'Private Dinner Party',
        li2: '6:00pm - 10:00pm',
        address: 'Donna Chang, 171 George St, Brisbane City QLD 4000',
        mapsUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3539.9206434635507!2d153.02200367715446!3d-27.471729816795964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915bd2cc4265c3%3A0x5b187efac2ade902!2sDonna%20Chang!5e0!3m2!1sen!2sau!4v1708386258477!5m2!1sen!2sau`
    }

]

const EventSection = (props) => {


    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="time-and-place">
            <div className="container">
                <SectionTitle MainTitle={'Time & Place'}/>
                <div className={"subhead"}>
                    <center>
                        <p>The celebrations will be split between two locations (we'll transport
                            you from
                            one to the other).</p>
                        <p> A ceremony at Delamore, and a reception party afterward at a private venue in Brisbane
                            city.</p>

                        <p> Times below are not yet finalised, but are unlikely to change significantly.</p>
                    </center>
                </div>
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    {/*<div className="wpo-event-img">
                                        <img src={event.Simg} alt="" height={"400px"} width={"600px"}/>
                                    </div>*/}
                                    <CustomCarousel items={
                                        event.images.map((img, i) => {
                                            return {
                                                src: img,
                                                altText: event.imageCaptions[i],
                                                caption: event.imageCaptions[i],
                                                key: i
                                            }
                                        })

                                    }/>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li><LocationMap linkText={event.address} mapUrl={event.mapsUrl}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;