import {Link} from "react-scroll";
import React, {Component} from "react";

export default class HeaderLinks extends Component {
    render() {
        return <>
            <li><Link activeClass="active" to="time-and-place" spy={true} smooth={true} offset={-100} duration={500}>Time
                & Place</Link></li>
            <li><Link activeClass="active" to="dress-code" spy={true} smooth={true} duration={500}>Dress Code</Link></li>
            <li><Link activeClass="active" to="transport" spy={true} smooth={true} duration={500}>Transport</Link></li>
            <li><Link activeClass="active" to="details" spy={true} smooth={true} offset={-80}
                      duration={500}>More Details</Link></li>

        </>
    }
}