import React, {Component} from "react";
import SectionTitle from "../components/SectionTitle";

const DressCodeSection = (props) => {
    return <section className={`wpo-portfolio-section section-padding ${props.pClass}`} id="transport">
        <div className="container">
            <SectionTitle MainTitle={'Transport'}/>
            <div className={"subhead"}>
                <center>
                    <p>You will need to organise your own transport <strong>to the ceremony</strong> and <strong>after
                        the reception dinner</strong></p>
                    <p>There is limited parking available at the ceremony for those who need to leave cars overnight, please try to carpool where possible.</p>
                    <p>We will organise transport for everyone from the ceremony to the reception dinner</p>
                </center>
            </div>
        </div>
    </section>
}
export default DressCodeSection;