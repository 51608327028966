import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import sImg1 from '../../images/story/1.jpg'
import sImg2 from '../../images/story/2.jpg'
import sImg3 from '../../images/story/3.jpg'
import sImg4 from '../../images/story/4.jpg'

import sIcon1 from '../../images/story/1.png'
import sIcon2 from '../../images/story/2.png'
import sIcon3 from '../../images/story/3.png'
import sIcon4 from '../../images/story/4.png'


import Shape from '../../images/story/shape.jpg'


const StorySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            sIcon:sIcon1,
            Shape:Shape,
            sTitle:'Gifts & Wishes',
            date:'',
            description:'There will be no place for gifts, and no wishing well. We just want your company and for you to have a great time celebrating with us.'
        },
        {
            Id: '2',
            sImg: sImg2,
            sIcon:sIcon2,
            Shape:Shape,
            sTitle:'Photography',
            date:'',
            description:'We are hiring a photographer for the ceremony, so please be present for that and don\'t take photos. For the reception dinner though, you may take photos and we ask that you share them with us afterward. We will be sharing the whole album on this website afterward.'
        },
        {
            Id: '3',
            sImg: sImg3,
            sIcon:sIcon3,
            Shape:Shape,
            sTitle:'Food & Drink',
            date:'',
            description:'There will be catering at the ceremony, but not quite a full meal. We suggest you have lunch beforehand. Dinner will be a paid-for banquet, we already know dietary restrictions for our beloved guests 😊'
        }
    ]



    return (

        <section className="wpo-story-section section-padding" id="details">
            <div className="container">
                <SectionTitle MainTitle={'Extra Details'}/>
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        <div className="tab-area">
                            <div className="tablinks">
                                <Nav tabs className="service-thumbs-outer">
                                    {Story.map((tab, titem) => (
                                        <NavItem key={titem}>
                                            <NavLink
                                                className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                onClick={() => { toggle(tab.Id); }}
                                            >
                                                {tab.sTitle}
                                                <span className="number">{tab.tabNumber}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                {Story.map((story, Sitem) => (
                                    <TabPane tabId={story.Id} key={Sitem}>
                                        <div className="wpo-story-item">
                                            {/*<div className="wpo-story-img">
                                                <img src={story.sImg} alt=""/>
                                            </div>*/}
                                            <div className="wpo-story-content">
                                                <div className="wpo-story-content-inner">
                                                    <span><img src={story.sIcon} alt=""/><h2>{story.sTitle}</h2></span>

                                                    <span>{story.date}</span>
                                                    <p >{story.description}</p>
                                                    <div className="border-shape">
                                                        <img src={story.Shape} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;